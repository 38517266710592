import React, { Component } from 'react';
import { Container, Row, Col, Form, FormGroup, Input, Button, ButtonGroup, Label,
  Card, CardBody, CardTitle } from 'reactstrap';
import { connect } from 'react-redux';
import { registerSession, passwordReset,clearServer,cancelTwoFactorAuthentication,
  switchLoginType, retryIdentityProviderLogin } from './../actions/currentUser';
import Spinner from '../components/Spinner';
import {get, isEmpty, assign, each, filter, reduce, sortBy, map} from 'lodash';
import {getLanguageList} from '../actions/language';
import './LoginIndex.css';
import {loadLanguages,multilanguage,changeLanguage} from 'redux-multilanguage';
import LoginLanguageRadioButton from './LoginLanguageRadioButton';
import {languageList, languageTranslations, valueNullToString} from '../languageTranslate';
import LoginTwoFactorAuthenticationModal from './LoginTwoFactorAuthenticationModal';

class LoginIndex extends Component {

  constructor(props) {
    super(props);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.isLoggedIn = this.isLoggedIn.bind(this);
    this.getLanguage = this.getLanguage.bind(this);
    this.adminLogin = this.adminLogin.bind(this);
    this.renderConnectingSpinner = this.renderConnectingSpinner.bind(this);
    this.renderServerSelection = this.renderServerSelection.bind(this);
    this.renderLoginPrompt = this.renderLoginPrompt.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.state = {
      password: '',
      username: localStorage.getItem('username'),
      errorMessage: '',
      pending: false,
      showSettings: false,
      language: [],
      languageCode: undefined
    }
  }

  componentDidMount() {
    this.loadLanguages();
    this.props.clearServer();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let invalid = get(nextProps, 'user.invalid');
    if (invalid) {
      clearTimeout(this.timeout);
      this.timeoutError = setTimeout(() => {
        this.setState({
          errorMessage: 'Login attempt failed',
          pending: false
        });
      }, 2000);
    }

    let languageCode = window.localStorage.getItem('lastlanguage');
    if(languageCode){
      let previousLanguage = get(this.props,`multilanguage.languages[${languageCode}]`);
      let nextLanguage = get(nextProps,`multilanguage[${languageCode}]`);
      if (isEmpty(previousLanguage) && !isEmpty(nextLanguage)) {
        this.props.changeLanguage(languageCode);
      }
		}
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if(isEmpty(this.state.language) && !isEmpty(nextState.language)) {
      this.loadLanguages(nextState.language);
    }
    let code2fa = get(nextProps, 'user.code2fa');
    if(code2fa && nextState.errorMessage === 'Login attempt failed') {
      this.setState({
        errorMessage: ''
      });
    }
  }

  // Remove? - react complaint memery leaked.
  componentWillUnmount() {
    if(this.timeout) {
      clearTimeout(this.timeout);
    }
    if (this.timeoutError) {
      clearTimeout(this.timeoutError);
    }
  }

  loadLanguages(data){
    // Below is to load languages, must call this once on app start,
    // and when user switch to new language that haven't loaded yet.
    const languages = assign({},this.props.multilanguage);
    each(data,lang =>{
      const languageCode = get(lang,'code');
      // get the language translation directly from the server for caching purpose
      languageTranslations(languageCode).then(data =>{
        languages[languageCode] = data;
        this.props.loadLanguages({languages})
      });
    });
  }

  lang = (string) =>valueNullToString(this.props.strings,string);

  async getLanguage () {
    // socket can not be open because user does not exist yet
    // get list of languages via server from restful
    const data = await languageList();
    console.log(data);
    const language = filter(data ,l =>l.active === '1');
    console.log(language);
    this.setState({language});
  }

  onFormSubmit(event) {
    event.preventDefault();
    this.setState({
      // password: event.target.value,
      errorMessage: '',
      pending: true
    });

    console.log(this.state);

    this.props.dispatch(registerSession({
      username: this.state.username,
      password: this.state.password,
      language: 'es' //this.state.languageCode
    }));
    /*
      If it takes longer than 7 seconds there's likely some issue, so alert the user
      and ask them to refresh and try again.
     */
    this.timeout = setTimeout(() => {
      if (!this.isLoggedIn()) {
        this.setState({
          errorMessage: 'An error occurred. Please refresh and try again.',
          pending: false
        });
      }
    }, 7000)
  }

  handlePasswordChange(event) {
    this.setState({
      password: event.target.value,
      errorMessage: ''
    })
  }

  handleUsernameChange(event) {
    this.setState({
      username: event.target.value,
      errorMessage: ''
    });
  }

  resetPassword(event) {
    this.props.dispatch( passwordReset(this.state.username) );
  }

  isLoggedIn() {
    // return !this.props.user.sessionId && localStorage.getItem('token')
    return !this.props.user.sessionId && localStorage.getItem('username') && localStorage.getItem('password')
  }

  adminLogin( serverchosen ) {
    this.props.dispatch(registerSession({
      username: this.state.username,
      // password: this.state.password,
      language: this.state.languageCode,
      serverchosen,
      token: localStorage.getItem('token')
    }));
  }

  renderConnectingSpinner() {
    clearTimeout(this.timeout);
    return ( <Spinner useLayout="true" invert="true" text="Establishing Connection.." /> );
  }

  renderServerSelection() {
    let color = localStorage.getItem( 'loginBackgroundcolor' );
    let style = {color: color === 'white' ? 'black' : 'white'};
    const names = sortBy( Object.keys( this.props.user.serveroptions ),
      parseInt( v => v.substr(3) ) );
    let rows = reduce( names, ( a, name ) => {
      if( name ) {
        a.push(
          <tr className="serverlist-server-row" key={name+'-server-row'}><td>
            <span key={name+'link'} style={style}
              onClick={(e) => { e.preventDefault(); this.adminLogin( name ); } }>{name}</span>
          </td></tr>
        );
        each( this.props.user.serveroptions[name], ( kioskinfo, kioskid ) => {
          a.push(
            <tr className="serverlist-container-row"  key={name+kioskid+'-container-row'}><td>
             <span style={style}>{kioskinfo.containerid + 4000} {kioskinfo.containername}</span>
            </td></tr>
          );
        } );

      }
      return a;
    }, [] );

    return (
      <table>
        <tbody>
          {rows}
        </tbody>
      </table>
    );
  }

  renderSettings() {
    const { loginTypes, activeLoginType } = this.props.user;
    return (
      <div>
        <span className="login-text">{this.lang('Third Party Identity Provider')}</span>
        <ButtonGroup>
        {map(loginTypes, ({name, key}) => {
          return (
            <Button key={key}
              color={activeLoginType == key ? 'primary' : undefined}
              onClick={() => {this.props.switchLoginType(key);}}
            >{this.lang(name)}</Button>
          );
        })}
        </ButtonGroup>
      </div>
    );

  }

  renderLoginPromptDefault() {
    const {strings:lang,currentLanguageCode} = this.props;

    return (
      <Row>
        {
          this.state.username &&
          this.state.password &&
          this.props.user.code2fa ?
          <LoginTwoFactorAuthenticationModal
          isOpen={this.props.user.code2fa}
          onSubmit={ code2fa =>{
            this.props.dispatch(registerSession({
              username: this.state.username,
              password: this.state.password,
              language: this.state.languageCode,
              code2fa,
              type2fa:'text:'
            }));
          }}
          onCancel={this.props.cancelTwoFactorAuthentication}
          /> : null
        }
        <Col md={{size: 4, offset: 4}}>
          <Form onSubmit={this.onFormSubmit}>
            <FormGroup>
              <Input
                name="username"
                id="username"
                placeholder={this.lang("username")}
                onChange={this.handleUsernameChange} />
            </FormGroup>
            <FormGroup>
              <Input
                autoComplete="new-password"
                type="password"
                name="password"
                id="password"
                placeholder={this.lang("password")}
                onChange={this.handlePasswordChange} />
            </FormGroup>
            <FormGroup check row>
              <Col md="12">
                { this.state.errorMessage ? <>
                  <Label style={{color: 'red'}}>{this.state.errorMessage}</Label>
                  <Button
                    color="primary"
                    // className="float-right"
                    disabled={!this.state.username}
                    onClick={this.resetPassword}
                  >
                    {this.lang('Reset Password')}
                  </Button> </> : null }

                { this.state.pending ?
                  <Spinner useLayout="false" invert="true" className="float-right submit-spinner" />
                  :
                  <Button color="primary" className="float-right">
                    {this.lang('Login')}
                  </Button>
                }
              </Col>
            </FormGroup>
          </Form>
        </Col>
      {/* move to settings page
        <Col md={12}>
          <LoginLanguageRadioButton
            language={this.state.language}
            currentLanguageCode={currentLanguageCode}
            selectLanguage={(languageCode)=>{
              window.localStorage.setItem('lastlanguage', languageCode);
              window.localStorage.setItem('language', languageCode);
              this.props.changeLanguage(languageCode);
              this.setState({languageCode});
            }}
            lang={lang}
          />
        {/*<p style={{color:'white'}}>
                {this.lang('Due to the unexpected release of iOS 14, we appreciate your patience and are currently working hard to ensure compatibility as we update the iLot app as quickly as possible.')}
              </p>*/}
      {/*</Col>*/}
      </Row>
    );
  }

  doPopup() {
    // window.open("https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=8e049f43-8ca0-4366-bff4-a9dd9c0c8ea9&response_type=code&redirect_uri=https%3A%2F%2Fwww.1micro.net%2Fad-auth%2F&response_mode=query&scope=https%3A%2F%2Fgraph.microsoft.com%2FUser.Read&state=12345");
  }
  
  renderLoginPromptAzureAD() {
    if( !this.state.popupDone ) {
      this.props.retryIdentityProviderLogin();
      this.setState({ popupDone: true });
    }

    return ( <>
      <div className="login-text">
        Waiting for identity verification from third party identity provider...
        <div>
          <Button color="primary"
            onClick={this.props.retryIdentityProviderLogin}>
            {this.lang('Ask Again')}
          </Button>
        </div>
      </div>
    </> );
  }

  renderLoginPrompt() {
    const { activeLoginType } = this.props.user;

    if ( this.isLoggedIn() || this.props.connectionStatus === 'closed') {
      clearTimeout(this.timeout);
      clearTimeout(this.timeoutError);
      return ( <Spinner useLayout="true" invert="true" text="Establishing Connection.." /> );
    } else if( this.state.showSettings ) {
      return this.renderSettings();
    } else if( activeLoginType == 'azure-ad' ) {
      return this.renderLoginPromptAzureAD();
    } else {
      return this.renderLoginPromptDefault();
    }
  }

  render() {
    let content;
    let classname = "login-wrapper";
    if (this.isLoggedIn() || this.props.connectionStatus === 'closed') {
      content = this.renderConnectingSpinner();
    } else if( this.props.user.serveroptions ) {
      content = this.renderServerSelection();
      classname = "";
    } else {
      content = this.renderLoginPrompt();
    }

    // if(!this.props.register && !this.isLoggedIn()) {
    //   return <Spinner useLayout="true" invert="true" text="Establishing Connection.." />
    // }
    return ( <>
      <div className={classname}>
        <Container>
          <Row className='logo-row'>
            <Col md={{size: 4, offset: 4}}>
              <img className="brand-logo" src={`${process.env.PUBLIC_URL}/logo.png`} alt="logo"/>
            </Col>
          </Row>
          {content}
        </Container>
      </div>
      <div className="login-wrapper-under">
        <Button color="primary"
          onClick={() => {this.setState({ showSettings: !this.state.showSettings });}}>
          {this.state.showSettings ? this.lang('Back') : this.lang('Settings')} 
        </Button>
      </div>
    </> );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getLanguageList: ()=> {
      return dispatch(getLanguageList())
    },
    clearServer: ()=> {
      return dispatch(clearServer())
    },
    cancelTwoFactorAuthentication: ()=>
      dispatch(cancelTwoFactorAuthentication()),
    loadLanguages: language =>{
      return dispatch(loadLanguages(language))
    },
    changeLanguage: language =>{
      return dispatch(changeLanguage(language))
    },
    switchLoginType: ( loginType ) => {
      return dispatch( switchLoginType(loginType) );
    },
    retryIdentityProviderLogin: () => {
      return dispatch( retryIdentityProviderLogin() );
    },
  };
};

const mapStateToProps = (state) => {
  // const register = get(state,'currentUser.register');
  let user = state.currentUser;
  return {
    user,
    // register,
    connectionStatus: state.connectionStatus,
    multilanguage: state.multilanguage.languages,
  }
};
export default connect(mapStateToProps,mapDispatchToProps)(multilanguage(LoginIndex));
