import socket from './../socket';

export const LANGUAGE_CHANGE = 'LANGUAGE_CHANGE';
export const languageChange = (language) => {
  return dispatch => {
    socket.send({ command: "languagechange", language });
    dispatch({ type: LANGUAGE_CHANGE });
  }
};

export const GET_LANGUAGE_LIST = 'GET_LANGUAGE_LIST';
export const getLanguageList = (language) => {
  return dispatch => {
    socket.send({ command: "languagechange", language });
    dispatch({ type: GET_LANGUAGE_LIST });
  }
};
