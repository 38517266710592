import _ from 'lodash';

const initialState = [];

export default function kiosks(state = initialState, action) {
  switch (action.type) {

    case 'RECEIVE_TECHSUPPORT':
      return receiveKioskTechSupportResponse( state, action );
    // case 'RECEIVE_HIERARCHYSET':
    case 'RECEIVE_HIERARCHYGET':
      return action.data.hierarchy.kiosks;
    case 'RECEIVE_KIOSKDETAILS':
      return receiveKioskDetail(state,action);
    case 'RECEIVE_KIOSKACTIVE':
      return updateKioskInState( state, action.data.kioskID, { active: action.data.active } );
    case 'RECEIVE_KIOSKRENAME':
      return updateKioskInState( state, action.data.kioskID, { kioskName: action.data.kioskName } );
    case 'RECEIVE_DRAWERTIERASSIGN':
      return receiveDrawerTierAssign(state,action);
    case 'UPDATE_KIOSK_NAME':
      return updateKioskInState( state, action.kiosk, { kioskName: action.name } );
    case 'RECEIVE_BINCREATEKIOSK':
      return addKiosk( state, action );
    case 'HANDLE_LOGOUT':
      return _.cloneDeep(initialState);
    default:
      return state;
  }
}

function addKiosk(state,action) {
  let bin = _.get(action,'data.bin.0');
  let active = _.toString(bin.active);
  let archive = _.toString(bin.archive);
  let companyID = _.toString(bin.companyID);
  let containerID = _.toString(bin.containerID);
  let kioskID = _.toString(bin.kioskID);
  return [
    ...state,{...bin,active,archive,companyID,containerID,kioskID}
  ]

}

function receiveKioskTechSupportResponse(state, action) {
  let data = _.get(action, 'data');
  let kioskID = _.get(data, 'kioskid');
  let result = _.get(data, 'result');
  
  if (Array.isArray(result)) {
    // If the result is an array, iterate over each object and format it concisely
    let formattedResult = result
      .map((item, index) => {
        return `Filesystem ${index + 1}: ${item.filesystem} | Size: ${item.size} | Used: ${item.used} | Available: ${item.available} | Percent Used: ${item.percent_used} | Mounted on: ${item.mounted_on}`;
      })
      .join('\n');
    alert(formattedResult);
  } else if (_.isObject(result)) {
    // If it's a single object, format it as a key-value pair
    let formattedResult = Object.entries(result)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
    alert(formattedResult);
  } else {
    alert(result);
  }
  
  return state;
}

function updateKioskInState( state, kioskID, updatedkioskdata ) {
  let key = _.findKey( state, { kioskID } );
  let kiosk = { ...state[key] };
  if( kiosk )
    return { ...state, [key]: { ...state[key], ...updatedkioskdata } };
  return state;
}

function receiveKioskDetail(state,action){
  let kioskID = _.get(action,'data.kioskid');
  let key = _.findKey( state, { kioskID } );
  let kiosk = { ...state[key] };

  if( kiosk ) {
    let keyMap = _.reduce( kiosk, ( a, v, k ) => {
      a[k.toLowerCase()] = k;
      return a;
    }, {} );
    _.each( action.data, ( v, k ) => {
      kiosk[keyMap[k.toLowerCase()] || k] = v;
    } );
    kiosk.drawerlayout = _.groupBy( kiosk.drawerlayout, 'cabinet' );
    return { ...state, [key]: kiosk };
  } else {
    return state;
  }
}

function receiveDrawerTierAssign(state,action) {
  let data = _.get(action,'data');
  let drawerid = _.get(data,'drawerid');
  let kioskID = _.get(data,'kioskid');
  let securityTier = _.toString(_.get(data,'tier'));
  let key = _.findKey( state, { kioskID } );
  let drawerObject = _.get(state,key);
  let drawerlayout = _.get(drawerObject,`drawerlayout`);
  let drawerKey = _.findKey(drawerlayout,drawers=>{
    let drawerIndex = _.findIndex(drawers,drawer=>drawer.drawerID === drawerid);
      return _.get(drawers,drawerIndex);
    }
  );
  let drawerlayoutArray = drawerlayout[drawerKey];
  let drawer = _.map(drawerlayoutArray,draws=>{
    return (draws.drawerID === drawerid) ? {...draws,securityTier} : draws;
  });
  return {
    ...state,
    [key]:{
      ...drawerObject,
      drawerlayout:
        {
          ...drawerlayout,
          [drawerKey]:drawer
        }
    }
  };
}
