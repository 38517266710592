import _ from 'lodash';

const initialState = {
  list: []
};

export default function language(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_LANGUAGEGETLIST':
      return recieveTagRangesReserve(state, action);
    default:
      return state;
  }
}

function recieveTagRangesReserve(state, action) {
  const list = _.get(action, 'data.list');
  return { ...state, list }
}
